import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import CodingLogos from "../components/codingLogos.js"
import PartnersLogos from "../components/partnerLogos.js"

import ReqCur from "../components/reqCur"

import Covid from "../components/covid"
import { Stripe } from "stripe.js"
import Checkout from "../components/checkout"

import Contacts from "../components/contactform"
import Scholarships from "../components/scholarships.js"
import InternationalStudents from '../components/internationalStudents.js'

import Inperson from "../pics/game_development_bootcamp.jpg"

import OurStudents00 from "../pics/students/barcelona_code_school_students_00.jpg"
// import OurStudents01 from '../pics/students/barcelona_code_school_students_01.jpg'
// import OurStudents02 from '../pics/students/barcelona_code_school_students_02.jpg'
// import OurStudents03 from '../pics/students/barcelona_code_school_students_03.jpg'

import Instalments from '../components/instalments.js'

// import Ting from "../pics/students/ting_profile.jpg"
// import Ross from "../pics/students/ross_sq.jpg"
// import Christine from "../pics/students/christine_chang.jpg"
// import Brie from "../pics/students/brie_frontpage.jpg"

// import CR from '../pics/BestOnlineBadge2020.png'
import switchup from "../pics/switchup2020.png"

import Countdown from '../components/Countdown';

import weLoveIndiesLogo from '../pics/partners/dyn-wli-logo-dark.png'
import ProgressBar from '../components/ProgressBar.js';

const GameDevBoocamp = () => {
	let courses = [{
		durationdays: 61,
		durationweeks: 9,
		fulldesc1: "9-week course, in-person, in English, full-time.",
		fulldesc2: "Registration prepayment 720€.",
		fullprice: 6200,
		image: "https://barcelonacodeschool.com/files/pics/game_development_bootcamp.jpg",
		priceoptions: [],
		shortdesc: "Become a certified game developer in 9 weeks",
		signupprice: 720,
		startdate: "April 19, 2021",
		startdates: [{availability:35, date:'January 6 - March 7, 2025'}],
		// , 'August 12 – October 11, 2024','October 14 – December 13, 2024'
		starttime: "9:00",
		tag: "gameDevBootcamp",
		title: "Game Development in-person Bootcamp",
	}]

	
	let renderCourses = () => {
		return courses[0].startdates.map((date, i) => (
			<div key={i} className="whiteCard bottomSpace flex300">
				<span>
					<h2><span className='highlighter'>{date.date}</span></h2>
					<h3>{courses[0].title}</h3>
					{i === 0 &&
						<>
							<h3>Starts in <Countdown endDate={`${date.date.split(' - ')[0]} ${date.date.slice(-4)} 09:00`} />
							</h3>
							<p>The group is filling up fast, reserve your seat now</p>
						</>
					}
					<p>{courses[0].shortdesc}</p>
				</span>
				<span>
					<p>{courses[0].fulldesc1}</p>
					<p>{courses[0].fulldesc2}</p>
					<ProgressBar percentage={date.availability} />
					{!date.date.includes("March 13") && <Link
						to="/register"
						state={{
							course: courses[0].title,
							date: date.date,
							cost: courses[0].signupprice,
							images: [courses[0].image],
							cryptoID: '43a235cf-9abf-46f3-9306-9d2f4c0242c6'
						}}
					>
						<button style={{ marginBottom: "1em" }}>Sign up</button>
					</Link>}
					{/*<button style={{ marginBottom: "1em" }} onClick={()=>window.location.href='https://docs.google.com/forms/d/e/1FAIpQLScnvOLYfe1OlYTZm2msfrtwcTQjXM7QNErcQKWkZiV-Xx3fOA/viewform?usp=send_form'}>Apply</button>*/}
					{/*<a
			href="https://commerce.coinbase.com/checkout/080c8a58-bd0a-4491-b30c-d7e2802d1d9a">
			<button>Sign up with crypto</button>
		</a>*/}
					{/*<script src="https://commerce.coinbase.com/v1/checkout.js?version=201807">
				</script>*/}
					{/*<p className="finePrint">
			By signing up you agree to the <a href="/about-us/terms-and-conditions/">Terms and Conditions</a> stated on the website.</p>*/}
				</span>
			</div>
		))
	}

	const styles = {
		partnerLogo: {
			width: '50%',
			maxWidth: '150px',
			margin: '0 auto',
			display: 'block',
		}
	}
	return (
		<div className="flexContent">
			<Helmet
				title="Game Development Bootcamp in Barcelona Code School - learn C#, Unity, game design, storytelling, game art, publishing"
				meta={[
					{
						name: "description",
						content:
							"Game Development Bootcamp in Barcelona Code School. Intensive 9-week course to become a game developer. Teaching C#, Unity, game design, storytelling, game art.",
					},
					{
						name: "keywords",
						content:
							"game development bootcamp, game development bootcamp in Barcelona, unity bootcamp, c# bootcamp",
					},
					{
						name: "viewport",
						content: "width=device-width, initial-scale=1",
					},
					{
						property: "og:title",
						content:
							"Game Development Bootcamp in Barcelona Code School - learn C#, Unity, game design, storytelling, game art, publishing",
					},
					{
						property: "og:description",
						content:
							"Game Development Bootcamp in Barcelona Code School. Intensive 9-week course to become a game developer. Teaching C#, Unity, game design, storytelling, game art, game theory.",
					},
					{
						property: "og:image",
						content:
							"https://barcelonacodeschool.com/files/pics/game_development_bootcamp.jpg",
					},
					{
						property: "og:url",
						content:
							"https://barcelonacodeschool.com/files/pics/game_development_bootcamp.jpg",
					},
					{ name: "twitter:card", content: "summary_large_image" },
				]}
				script={[{ src: "https://js.stripe.com/v3/" }]}
			/>

			<img
				className="banners flex1200 offsetImage"
				src={Inperson}
				alt="Game Development Bootcamp in Barcelona Code School"
			/>

			<h1 className='transparentContainer flex225'><span className="highlighter">Game Development in-person Bootcamp</span> in Barcelona Code School</h1>

			<h2 className='transparentContainer flex450'>Become a one-man mean machine capable of creating a game from scratch or work in a team</h2>

			<h2 className='transparentContainer flex450'>Guaranteed internship in <a href='https://soamazing.games/' target='_blank' rel=''>our game design studio</a> after the graduation</h2>

			<article className='transparentContainer flex450'>
			<video src={'https://barcelonacodeschool.com/files/videos/GameDevStudentsWorks1.mp4'} width="100%" height="100%" controls autoPlay muted loop type="video/mp4"/>
			</article>

			<div className='transparentContainer flex450'>
				<h3>What is this bootcamp?</h3>
				<div className='whiteCard'>
					<p>We prepared a <strong>Complete Game Development Bootcamp</strong> for those who are interested in the <strong className='highlighter'>full cycle of game production</strong>.</p>
					<p>During this course you will try on <strong className='highlighter'>all the roles involved into a game development</strong> process, exactly how indie developers work, but of course the focus would be on the <strong className='highlighter'>game developer position</strong>.</p>
					<p>Through the course you will be game designer, story writer, artist, developer and producer all-in-one!</p>
					<p>As the focus is on development, you will spend most of your time mastering <strong className='highlighter'>C# and Unity</strong> engine. In the end you will have several <strong className='highlighter'>2D and 3D games finished</strong> and and we will help to create your <strong className='highlighter'>portfolio</strong> to showcase them and provide <strong className='highlighter'>job support</strong>!</p>
				</div>
			</div>

			{/*		<div className='transparentContainer flex225'>
		<p className='whiteCard flex225'>Since 2017 with continuous updates and improvements 🤓</p>
		<div className="flexOneRowCenter flex300">
		<img
		className="badge"
		src={switchup}
		alt="SwitchUp Best Coding Bootcamp 2020"
		/>
		</div>
		</div>*/}

			<div className="transparentContainer">
				<h4>Share on social media</h4>
				<div className="socialsharebuttons">
					<div className="socialshare" style={{ 'marginLeft': 0 }}>
						<a
							className="twitter-share-button "
							href="https://twitter.com/intent/tweet?text='Game Development in-person Bootcamp in Barcelona Code School'&url=https://barcelonacodeschool.com/game-development-bootcamp"
							data-size="large"
						>
							<button>tweet</button>
						</a>
					</div>

					<div
						className="fb-share-button socialshare"
						data-href="https://developers.facebook.com/docs/plugins/"
						data-layout="button"
						data-size="small"
					>
						<a
							target="_blank"
							href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/game-development-bootcamp"
							className="fb-xfbml-parse-ignore"
						>
							<button>facebook</button>
						</a>
					</div>
				</div>
			</div>






			<article className='transparentContainer flex300'>
				<h2>Course format</h2>
				<ul className=' whiteCard neonList'>
					<li>360 hours of intensive <strong className="highlighter">in-person hands-on</strong> training</li>
					<li>Zero to hero in	<strong className="highlighter">9 weeks</strong></li>
					<li>Monday to Friday, 9:00 to 18:00</li>
					<li>Small groups of <span className='highlighter'>up to 6 students</span></li>
					<li>The course is taught in	<strong className="highlighter">English</strong></li>
					<li><strong className="highlighter">Scholarships</strong> are available</li>
					<li>Coding bootcamp teaching <strong className="highlighter">game development</strong>!</li>
					<li>A <strong className="highlighter">graduate certificate</strong></li>
					<li>Job support</li>
				</ul>
			</article>




			<article className="transparentContainer flex600">
				<h2>What you'll learn</h2>
				<div className='whiteCard'>
					{/*<CodingLogos />*/}
					<h3>Core skills</h3>
					<ul className='neonList'>
						<li>How to build <strong className="highlighter">2D games</strong></li>
						<li>How to build <strong className="highlighter">3D games</strong></li>
						<li>How to build <strong className="highlighter">multiplayer games</strong></li>
						<li><strong className="highlighter">C#</strong> – the language of game development</li>
						<li><strong className="highlighter">Unity</strong> – one of the most powerful and used game engine and editor in the industry</li>
					</ul>
					<h3>Extra skills</h3>
					<ul>
						<li><span className="highlighter">Game design</span>: how to make a game structured and engaging</li>
						<li><span className="highlighter">Story design</span>: rules and principles of storytelling</li>
						<li><span className="highlighter">Game art</span>: how to create, generate or find the art you need</li>
						<li><span className="highlighter">Game genres</span> and their specifics</li>
						<li><span className="highlighter">Creating visuals</span> for publishing</li>
						<li><span className="highlighter">User Interface design</span>: how to create UI of your game</li>
						<li><span className="highlighter">User Experience (UX) for games</span>: how to not confuse your players</li>
					</ul>
				</div>
			</article>



			<article className="transparentContainer flex900" >
				<h2>The outcomes</h2>
				<div className='whiteCard'>
					<ul className='neonList'>
						<li><span className="highlighter">Get hired as a game developer</span>. We will take you to the job-ready level so you will be able to apply for a junior game developer positions in any game development studio. Only in Catalonia there are <a href='https://www.devuego.es/bd/mapa-estudios/?comunidad=catalunya#listado' target="_blank">more than 150 of them</a>.</li>
						<li><span className="highlighter">Go indie</span>. You will be able to start building your own games and publishing them. </li>
						<li><span className="highlighter">Stay with us</span>. Stay for the internship with us to work on the game prototypes at <a href='https://soamazing.games/' rel="noopener noreferrer" target='_blank'>So Amazing Games</a> which are if published will allow you to stay for a full-time paid job.</li>
					</ul>
				</div>
			</article>



			<article className='transparentContainer flex450' id='syllabus'>
				<h2>Get the syllabus</h2>
				<div className='whiteCard'>
					<p>Subscribe to our mailing list and get the syllabus now.</p>
					<ReqCur course="Game Development Bootcamp" link="https://barcelona-code-school.notion.site/Game-Development-Bootcamp-Syllabus-177501e9b08a4e54b7a3d7fa4cc6e387?pvs=4" courselink="https://barcelonacodeschool.com/game-development-bootcamp/" />
				</div>
			</article>

			<div className="transparentContainer flex225">
				<h2>Extra perks</h2>
				<div className="whiteCard bottomSpacer">
					<p>All students get free access to the massive sounds and music library by Dynamedion's we♥️indies!</p>
					<img src={weLoveIndiesLogo} alt='we love indies' style={styles.partnerLogo} />
				</div>
			</div>

			<article className='transparentContainer flex300'>
				<h2>Tuition</h2>
				<div className='whiteCard'>
					<h2>6200€</h2>
					<p>720€ paid upon registration, the remaining is split in two payments due before the course begins.</p>
					<p>6 students max per group. We want this to be efficient, not sink or swim experience for you!</p>
					<Instalments />
				</div>
			</article>


			<article className="transparentContainer flex600">
				<h2>Access

					<span
						id="cohorts"
						style={{
							position: "relative",
							top: "-50px",
							visibility: "hidden",
						}}
					>
						.
					</span>
				</h2>

				{/*<Covid course={"jsinperson"} />*/}
				<div className='flexContent'>{courses.length > 0 && renderCourses()}</div>

			</article>







			{/*<section className="flex1200 transparentContainer">
		<h2>Students stories</h2>
		<p>
		Check some of the reviews <a href="/about-us/testimonials/">here</a>
		</p>

		<div className="flexContent">
		<div className="whiteCard flex225">
		<img className="profilePic offsetImage" src={Christine} />
		<h3>Christine Chang</h3>
		<h4>Junior Developer at Green Power Monitor, Barcelona</h4>
		<p>
		"I wish I had done code camp 5 years ago. I would have
		been working all these years making up the tuition cost
		in a month or two. I chose Barcelona Code School because
		they had a focus on React and React Native."
		</p>
		<p>
		<a href="/student-story-christine-chang">
		Read full story
		</a>
		.
		</p>
		</div>
		<div className="whiteCard flex225">
		<img className="profilePic offsetImage" src={Brie} />
		<h3>Brianna Fritsch</h3>
		<h4>Website Manager at IESE, Barcelona</h4>
		<p>
		"The instructors continued to motivate me and remind me
		that I would get it if I kept practicing. They
		encouraged me to keep going because if it was that easy
		to become a developer then everyone would be doing it.
		They really helped me to keep going even my moments of
		frustration and defeat."
		</p>
		<p>
		<a href="/students-story-brie">Read full story</a>.
		</p>
		</div>

		<div className="whiteCard flex225">
		<img className="profilePic offsetImage" src={Ting} />
		<h3>Ting Liu</h3>
		<h4>Junior developer at Metro AG, Berlin</h4>
		<p>
		"I made an extra studying plan, I spent 1-2 hours a day
		at home reading some programming-related articles to
		help myself understand better my code."
		</p>
		<p>
		<a href="/students-outcomes/ting">Read full story</a>.
		</p>
		</div>

		<div className="whiteCard flex225">
		<img className="profilePic offsetImage" src={Ross} />
		<h3>Ross Fleming</h3>
		<h4>Front-end developer at Raet, Madrid</h4>
		<p>
		“If you put a 100% percent of yourself, you get the same
		in return and can start looking for your new job as a
		developer. It took me a bit more than one month to find
		a job, it is incredible. To those who have never had
		experience of intensive training, bootcamps can seem to
		be some kind of wizard schools”.
		</p>
		<p>
		<a href="/students-outcomes/ross">Read full story</a>.
		</p>
		</div>
		</div>
		</section>*/}



			{/*		<article className="transparentContainer flex450">
		<h2>Why JavaScript?</h2>
		<div className='whiteCard'>
		<ul>
		<li>According to StackOverflow.com, the biggest community website for professional and enthusiast programmers, JavaScript has been <a href="https://survey.stackoverflow.co/2022/#programming-scripting-and-markup-languages" target='blank'> the world's most popular language for 10 years in a row </a>. </li>
		<li>Knowing JavaScript opens the door into web and mobile apps development without any limitations. Back-end, front-end, full-stack, iOS and Android apps development is all possible with JavaScript. </li> 
		<li>Full-stack developer is <a href="https://barcelonacodeschool.com/why-learning-javascript-is-relevant-today"> the most in-demand role in the job market </a>. </li>
		</ul>
		</div>
		</article>*/}

			<article className="transparentContainer flex450">
				<h2>Why Barcelona Code School?</h2>
				<div className='whiteCard'>
					<ul>
						<li><span className="highlighter">Cutting edge curriculum</span> tailored to job market and companies’ needs</li>
						<li>Instructors, who <span className="highlighter">know how to teach</span></li>
						<li>Fast track from beginner to job-ready coder</li>
						<li>The best <span className="highlighter">students/mentors ratio</span></li>
						<li><span className="highlighter">Job support</span> for the students</li>
						<li>Life-time career support</li>
						<li>Premium location – cozy campus in the downtown of Barcelona away from the touristic crowds</li>
					</ul>
				</div>
			</article>




			<InternationalStudents />

			<Scholarships />

			<article className='transparentContainer flex300'>
				<h2>Financing</h2>
				<div className='whiteCard'>
					<p>We can assist in getting a student loan which usually has lower rates than consumer loans.</p>
					<p>To do so we can send you a proforma invoice for the selected course which you can attach to the student's loan application.</p>
					<p>Please <a href="https://docs.google.com/forms/d/e/1FAIpQLSeIeOLI2k3DlSQmwdUFPMawFr7-LUVWEnd2Huy4mMaRu0bncg/viewform" target="_blank">fill out this form</a>.</p>
					<Instalments />
				</div>
			</article>








			<div className='flexFullWidth'><img className="banners offsetImage" style={{ width: '100%' }} src={OurStudents00} /></div>



			{/*		<article className="transparentContainer flex450 transparentContainer">
		<h2>Wondering about day-to-day workflow?</h2>
		<div className='whiteCard'>
		<ul className='neonList'>
		<li>We begin with the code reviews and and questions you might have about the homework from the day before</li>
		<li>After clearing all the issues we are ready to start with a lecture introducing a new topic with live coding sessions and interaction with students to keep you engaged</li>
		<li>In programming theory is useless without practicing – after the lecture it's time for you to start working on the exercises focused on the concept of the day. With instructors being around all day ready to clarify any doubts or answer any questions and pair programming with the fellow students you would be surprised at the progress you'll achieve</li>
		<li>After lunch break we continue coding and dig deeper into the new materials by sharing the insights, best practices and doing code reviews</li>
		<li>Finishing the day with a quick recap and setting the goals for the next day </li>
		</ul>
		</div>
		</article>*/}




			<article className='transparentContainer flex450'>
				<h2>FAQ</h2>
				<div className='whiteCard'>
					<details>
						<summary><strong>What computer would I need?</strong></summary>
						<p>You will need a fastest laptop you can have with Unity installed. Your platform will determine how you will be able to build and distribute your game. On Windows you will be publishing for Android (Google Play). On Mac you can do both for iOS (AppStore) and Android (Google Play). You can also build for the desktop OS and web.</p>
					</details>

					<details>
						<summary><strong>What is the language of this course?</strong></summary>
						<p>English.</p>
					</details>

					<details>
						<summary><strong>Do I have to speak Spanish?</strong></summary>
						<p>For the course you do not need Spanish. Everybody at our school speak English. To feel comfortable in Barcelona Spanish would help but nowadays in most of the places local people do speak English.</p>
					</details>

					<details>
						<summary><strong>What qualification I will get from this course?</strong></summary>
						<p>If completed successfully you will become a junior game developer. Meaning you will know how to build games and be able to apply for the junior developer's position.</p>
					</details>

					<details>
						<summary><strong>Are there any accommodation options for the foreign
							students?</strong></summary>
						<p>Make sure to check <a
							href="https://www.uniplaces.com/?utm_source=vero&utm_medium=email&utm_content=control&utm_campaign=Institutionals_Update%20Tracking%20%26%20Infos_%20Actives%2FNon%20Actives&utm_term=Newsletter&vero_id=office%40barcelonacodeschool.com&vero_conv=Thn_9YrfYIN-ruvFIRw_AXMfUSaz0geBOB0eCva7t8s8rOGu5-ZqtuEkgRV0KkLu2FE-G1Qg-iJ5o_8ynL7xjBY93Og9tI-Api0%3D"
							target="_blank"
						>Uniplaces (use UP25BCS promo code to get 25% discount)</a>, <a
							href="https://www.spotahome.com/barcelona"
							target="_blank"
						>Spotahome</a> and <a href="https://barcelona-home.com/en" target="_blank">Barcelona home</a>.</p>
					</details>

					<details>
						<summary><strong>What about cost of living in Barcelona, how much will I spend apart from the course tuition?</strong></summary>
						<p>Barcelona, compared with other metropolitan European cities, is quite affordable or even cheap place to live. According to the latest survey, the average cost of living for students in Barcelona is around 1000 Euros per month.</p>
						<p>Expect to spend 300-600€ to rent a room or 600-1000€ for the apartment, around 10€ for a lunch in a restaurant, 10€ for a public transport 10-ride pass.</p>
					</details>

					<details>
						<summary><strong>How good would a typical graduate be after completing the course?</strong></summary>
						<p>You will learn the skills to begin as a junior game developer, will know theory and practicalities of building a game have a solid foundation to improve your expertise.</p>
					</details>

					<details>
						<summary><strong>Is there a pre-course?</strong></summary>
						<p>Yes. If you never did anything related to programming and do not have a knowledge of any programming language we will send you the materials to study to come prepared! We estimate it to take around 40 hours if you start from absolute 0 level.</p>
					</details>

					<details>
						<summary><strong>How does your price compare to other schools?</strong></summary>
						<p>There are no many school offering game development bootcamps. It’s safe to say that our price is within lower competitive range. We strive to provide the personalized approach thanks to smaller group size and attract highly qualified instructors motivated to share their expertise with students. You will love it, we guarantee!</p>
					</details>
				</div>
			</article>

			<div className='flex450' id='contactus'>
				<Contacts
					title={"Inquiry about the course"}
					subject={"Inquiry about Game Development in-person bootcamp"}
				/>
			</div>

			{/*		<article className='transparentContainer flex900'>
		<h2>Some of the companies where our graduates work or worked</h2>
		<div>
		<PartnersLogos />
		</div>
		</article>*/}

		</div>

	)
}

export default GameDevBoocamp
